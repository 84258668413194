.blackg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/bg-img.png");
    bottom: 1rem;
} 
@media all and (max-width: 450px) {
   
    .column-1 {
      
      order:2;
    }
     .column-2 {
    
      order: 1;
    }
  }

  @media (min-width: 768px) {
    .column-1 {
      
        order:2;
      }
       .column-2 {
      
        order: 1;
      }
   }
 

.title{
    margin-top: 6rem;
}
.carousel {
    width: 800px;
    height: 980px;
    margin: auto;
}
.carousel-control-prev{
    height: 300px;
}
.carousel-control-next{
    height: 300px;
}
.carousel .carousel-control-prev-icon{
    visibility: hidden;
}
.carousel .carousel-control-prev-icon{
    visibility: visible;
}
.carousel .carousel-control-next-icon{
    visibility: hidden;
}
.carousel .carousel-control-next-icon{
    visibility: visible;
}


/* Toogle text */
.text-show{
    color:#0158a2;;
    font-weight: bold;
    cursor: pointer;
}
.text-show:hover{
    font-weight: bold;
    color: green;
}


.boton-descarga{
   
    margin-right: 30px;
    background: #5E5EEC;
    background: linear-gradient(to right, #5E5EEC 25%, #0ec1e6  65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}





