 .sidebar {
    position: fixed;
    top: -0.80rem;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all .5s ease;
    z-index: 1;

}

.sidebar header {
    font-size: 22px;
    color: white;
    text-align: center;
    line-height: 70px;
    background: #000000;
    user-select: none;

}

.sidebar ul a {
    display: block;
    height: 100%;
    width: 100%;
    line-height: 65px;
    font-size: 20px;
    color: white;
    padding-left: 4px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid black;
    transition: .3s;

}

ul li:hover a{
    padding-left: 30px;
    
}

.sidebar ul a i {
    margin-right: 5px;
}

#check {
    display: none;

}

label #btn, label #cancel {
    position: absolute;
    cursor: pointer;
    background: #000000;
    border-radius: 3px;
}

label #btn {
    left: 40px;
    top: 23px;
    font-size: 35px;
    color: white;
    padding: 6px 12px;
    transition: all .5s;
}

label #cancel {
    z-index: 1;
    left: -195px;
    top: 17px;
    font-size: 30px;
    color: #e9e3e3;
    padding: 4px 9px;
    transition: all .5s ease;
    z-index: 2;

}

#check:checked ~ .sidebar {
    left: 0;
}


#check:checked ~ label #btn {
    left: 250px;
    opacity: 0;
    pointer-events: none;
}

#check:checked ~ label #cancel {
   left: 195px;
}



