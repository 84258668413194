.button-map{
  margin-top: 4rem;
}


.dataSend{
  display: none;
}
.imageHolder{
   width: 300px;
   height: 300px;
}
img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.circular-bar{
  width: 150px;
  height: 100px;
  align-items: center;
}

.map-responsive {
  overflow:hidden;
  padding-bottom:400px; 
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

#fileInput{
  display: none;
}

.fa-cloud-arrow-up{
  cursor: pointer;
 
}
.boton{
  border: none;
}

.file{
  left:1.5rem;
  top:1rem;
  height:200px;
  width:200px;
  position:absolute;
}

a{
  text-decoration: none;
  color: #fff;
}
.css-button-gradient--1  {
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 10px;
  border: none;
  background-size: 120% auto;
  background: rgb(94,94,236);
  background: linear-gradient(180deg, rgba(94,94,236,1) 0%, rgba(14,193,230,1) 100%)
}

.image-recent{
  max-width: 200px;
  max-height: 200px;
}

.video-test{
  max-width: 100%;
  max-height: 100%;
}	



.css-button-gradient--1 a:hover {
  background-position: right center;
  text-decoration: none;
  color: rgba(44, 44, 44, 0.267);
}
.css-button-gradient--1:active {
  top: 2px;
}

#fileInput{
  display: none;
}

.buton-inicio{
  --bs-gutter-y:0.4rem;
}
.footer{
  position:fixed;
  left:10px;
  bottom:0px;
  height:30px;
  width:100%;
 
}

.css-button-gradient--2 {
  width: 200px;
  color: white;
  text-align: center;
  padding: 8px 13px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  font-size: 23px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  border-radius: 10px;
  border: none;
  background-size: 120% auto;
  background: rgb(94,94,236);
  background: linear-gradient(180deg, rgba(94,94,236,1) 0%, rgba(14,193,230,1) 100%)
}

.image-recent {
  max-width: 600px;
  max-height: 600px;
}

.css-button-gradient--2 a:hover {
  background-position: right center;
  text-decoration: none;
  color: rgba(44, 44, 44, 0.267);
}
.css-button-gradient--2:active {
  top: 2px;
 
}

.file-responsive{
  object-fit: cover;
  width:24rem;
  height:13rem;
  border-radius: 0.6rem;
}
.imageHolder{
  width:200px;
  height:200px;
}

.image-recent{
  
  height: 250px;
width: 100%;
object-fit: cover;
margin-bottom: 1rem;
border-radius: 0.6rem;
}

.img-horizontal{
  display:flex;
 
  flex-wrap:wrap;
  align-items:center;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
   .graficos{
    margin-top: 5rem;
   }
}

@media (min-width: 481px) and (max-width: 767px) {
  .graficos{
    margin-top: 5rem;
   }
}

.text-column{
 
 
  color: #C797EB;
  background-image: -webkit-linear-gradient(0deg, #C797EB 50%,#4ECDC4 62%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;

  

}

.responsive-boton{
  display: flex;
  flex-wrap: wrap;
 
}