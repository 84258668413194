
  
.title-banner{
      color: #f6ffd9;
      font-size: 26px;
      letter-spacing: 3;
      max-width: 100%;
      
  }
  /* Por debajo de 700px */
@media screen and (max-width: 700px){
  .title-banner{
    font-size:18px;
    max-width: 700px;
    margin: 0 auto;
  }
 
  }
  /* Por debajo de 400px */
  @media screen and (max-width: 400px){
  .title-banner{
  font-size:16px;
  max-width: 700px;
  margin: 0 auto;
  }

  }




  .wrap-banner{
    max-width: 100%;
    max-height: 100%;
    margin-top: 4rem;
    display:flex;
    justify-content:center;
    align-items:center;
     height:47vh;
   background:url(https://images.pexels.com/photos/66997/pexels-photo-66997.jpeg?cs=srgb&dl=nature-sky-sunset-the-mountains-66997.jpg&fm=jpg);
   background-size:cover;
    background-position:center;
  }